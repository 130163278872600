<template>
    <b-modal ref="modalDps" hide-footer>
        <template v-slot:modal-title>
            {{ $t('gynecologie.titre_dps_saillie_ws') }}
        </template>

        <div v-if="!modal_dps_loaded">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="modal_dps_loaded">
            <template v-if="!dps.length">
                {{ $t('gynecologie.aucune_donnee_dps_saillie_ws') }}
                </template>
                <template v-else>
                <div class="box my-3" v-for="data in dps" v-bind:key="data.monteSaillie">
                    {{ $t('horse.mare') }} : <b> {{ data.jumentNom }}</b>
                    <br>
                    {{ $t('horse.etalon') }} : <b>{{ data.etalonNom }}</b>
                    <br>
                    {{ $t('monte.porteuse') }} : <b>{{ data.porteuseNom }}</b>
                    <br>
                    {{ $t('monte.date_premier_saut') }} : <b>{{ data.monteDatePremierSaut_format }}</b>
                    <br>
                    {{ $t('compte.datenaissance') }} : <b>{{ data.produitDateNaiss_format }}</b>
                    <br>
                    {{ $t('horse.nom_naissance') }} : <b>{{ data.produitNom }}</b>
                    <br>
                    {{ $t('horse.nom_retenue') }} : <b>{{ data.nomRetenu }}</b>

                    <e-select
                        v-model="data.acte_type"
                        id="actestype_id"
                        track-by="actestype_id"
                        label="actestype_label"
                        :placeholder="$t('acte.selectionnez_acte')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="actes_types"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        :group-select="false"
                    >
                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                    </e-select>

                    <div class="text-center mt-3">
                        <b-form-checkbox v-model="data.selected"></b-form-checkbox>
                    </div>
                </div>

                <div class="text-center mt-3">
                    <b-button variant="primary" :disabled="loading_add_dps" @click="add_dps()">
                        <font-awesome-icon v-if="loading_add_dps" :icon="['fas', 'spinner']" pulse />
                        {{ $t('global.ajouter') }}
                    </b-button>
                    <b-button class="ml-2" variant="secondary" v-if="seasonmares" :disabled="loading_add_dps_commentaire" @click="add_dps_commentaire()">
                        <font-awesome-icon v-if="loading_add_dps_commentaire" :icon="['fas', 'spinner']" pulse />
                        {{ $t('gynecologie.add_dps_commentaire') }}
                    </b-button>
                </div>
            </template>
        </div>
    </b-modal>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common'
    import Gynecologie from "@/mixins/Gynecologie.js"
    import ActeType from "@/mixins/ActeType.js"
    import _groupBy from 'lodash/groupBy'

    export default {
        name: 'ModalAskDPS',
        mixins: [Gynecologie, ActeType],
        props: {
            seasonmares: {
				type: Boolean,
				default: () => ( false )
			},
        },
        data () {
            return {
                modal_dps_loaded: false,
                actes_types: null,
                loading_add_dps: false,
                loading_add_dps_commentaire: false,
                dps: null
            }
        },
        methods: {
			async openModal(season_id, items) {
            	this.modal_dps_loaded = false
                this.$refs['modalDps'].show()
                this.actes_types = await this.getActeTypeSaillie()
                const all_dps = []
                await Common.asyncForEach(items, async (item) => {
                    let dps = await this.getInfoSailliesWs(item.mare_id, season_id)
                    for(let i = 0; i < dps.length; i++) {
                        dps[i].selected = false
                        dps[i].acte_type = this.actes_types[0]
                        dps[i].horse = item.mare_id
                        dps[i].sm_id = item.seasonmare_id
                        all_dps.push(dps[i]);
                    }
                })
                this.dps = all_dps
                this.modal_dps_loaded = true
            },
			async add_dps() {
				this.loading_add_dps = true;

                const checked = this.dps
                    .filter(_dps => _dps.selected);

                const checked_group = _groupBy(checked, 'horse')

                for(let horse in checked_group) {
                	let data = checked_group[horse].map(saillie => {
                		return {
                			monteSaillie: saillie.monteSaillie,
                        	monteAnnee: saillie.monteAnnee,
                        	monteActeType: saillie.acte_type.actestype_id
                        }
                	})
                	await this.AddInfoSailliesWs(horse, data)
                }

                await this.$sync.force(true, true)
                this.successToast()
                this.$emit('submit')
                this.$refs['modalDps'].hide()
                this.loading_add_dps = false;
			},
			async add_dps_commentaire() {
                this.loading_add_dps_commentaire = true;

                const checked = this.dps
                    .filter(_dps => _dps.selected);

                for(let i in checked) {
                    let commentaire = this.getTrad('horse.etalon')+' : '+(checked[i].etalonNom ?? '')+'\n'+this.getTrad('monte.porteuse')+' : '+(checked[i].porteuseNom ?? '')+'\n'+this.getTrad('monte.date_premier_saut')+' : '+(checked[i].monteDatePremierSaut_format ?? '')+'\n'+this.getTrad('compte.datenaissance')+' : '+(checked[i].produitDateNaiss_format ?? '')+'\n'+(this.getTrad('horse.nom_naissance') ?? '')+' : '+(checked[i].produitNom ?? '')+'\n'+this.getTrad('horse.nom_retenue')+' : '+(checked[i].nomRetenu ?? '')+'\n'+this.getTrad('horse.type_monte')+' : '+this.getTrad('actes_type.'+checked[i].acte_type.actestype_code)+''

                   	await this.editSeasonMare(checked[i].sm_id, {seasonmare_commentary: commentaire});
                }

                this.loading_add_dps_commentaire = false;
            },
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
    }
</script>